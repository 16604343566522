import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../HomePage/HopePage';


function RoutingPage() {
    return (
        <Router>
            <Routes>
                {/* Use 'element' and wrap the component in JSX */}
                <Route path="/" element={<HomePage />} />
            </Routes>
        </Router>
    );
}

export default RoutingPage;