import React, { useEffect } from 'react';
import logo from '../images/Anjani Innovations Logo-1.png';
import reactLogo from '../images/tech_logos/react_logo.png';
import flutterLogo from '../images/tech_logos/flutter-logo.jpg';
import nodeJsLogo from '../images/tech_logos/nodejs_logo.webp';
import pythonLogo from '../images/tech_logos/python-logo.png';
import angularLogo from '../images/tech_logos/angular_logo.png';
import awsLogo from '../images/tech_logos/aws.png';
import dotnetLogo from '../images/tech_logos/dotnet_core.png';
import msSqlLogo from '../images/tech_logos/ms_sql_logo.png';
import mySqlLogo from '../images/tech_logos/mysql_logo.jpg';
import mongoDbLogo from '../images/tech_logos/mongo_db.png';
import { Link } from 'react-router-dom';

const HomePage = () => {

    useEffect(() => {
        const scrollLeftButton = document.querySelector('.scroll-left');
        const scrollRightButton = document.querySelector('.scroll-right');
        const techGrid = document.querySelector('.tech-grid');

        // Ensure that the elements exist before adding event listeners
        if (scrollLeftButton && scrollRightButton && techGrid) {
            scrollLeftButton.addEventListener('click', () => {
                techGrid.scrollBy({
                    left: -200, // Adjust this value
                    behavior: 'smooth'
                });
            });

            scrollRightButton.addEventListener('click', () => {
                techGrid.scrollBy({
                    left: 200, // Adjust this value
                    behavior: 'smooth'
                });
            });
        }

        // Cleanup the event listeners on component unmount
        return () => {
            if (scrollLeftButton && scrollRightButton) {
                scrollLeftButton.removeEventListener('click', null);
                scrollRightButton.removeEventListener('click', null);
            }
        };
    }, []);

    return (
        <div className="homepage">
            {/* Header */}
            <header className="header">
                <Link to="/" className="logo-link">
                    <div className="logo">
                        <img className="logo-image" src={logo} alt="Ai Network Logo" />
                        <span>Ai Network</span>
                    </div>
                </Link>
                <nav className="nav">
                    <a href="#home">Home</a>
                    <a href="#about">About Us</a>
                    <a href="#products">Products</a>
                    <a href="#technologies">Technologies</a>
                    <a href="#blog">Blog</a>
                    <a href="#contact">Contact</a>
                    <button className="cta-btn">Get in Touch</button>
                </nav>
            </header>

            {/* Hero Section */}
            <section className="hero">
                <h1>Empowering Innovation Through Technology</h1>
                <div className="hero-buttons">
                    <button className="primary-btn">Explore Our Solutions</button>
                    <button className="secondary-btn">Request a Demo</button>
                </div>
            </section>

            {/* About Us Snapshot */}
            <section className="about-us" id="about">
                <h2>Who We Are</h2>
                <p>Ai Network is focused on delivering innovative software solutions that bring your ideas to life.</p>
                <a href="#about" className="learn-more-btn">Learn More About Us</a>
            </section>

            {/* Products Overview */}
            <section className="products" id="products">
                <h2>Our Products</h2>
                <div className="product-cards">
                    <div className="product-card">
                        <h3>AI Clinics</h3>
                        <p>Clinic management software</p>
                        <a href="#ai-clinics" className="learn-more-btn">Learn More</a>
                    </div>
                    <div className="product-card">
                        <h3>AI Research</h3>
                        <p>Thesis management software</p>
                        <a href="#ai-research" className="learn-more-btn">Learn More</a>
                    </div>
                </div>
            </section>

            <section className="technologies" id="technologies">
                <h2>Technologies We Work With</h2>
                <div class="tech-carousel">
                    <button class="scroll-left">&lt;</button>
                    <div class="tech-grid">
                        <div class="tech-item">
                            <img src={reactLogo} alt="React" />
                            <p>React</p>
                        </div>
                        <div class="tech-item">
                            <img src={angularLogo} alt="Python" />
                            <p>Angular</p>
                        </div>
                        <div class="tech-item">
                            <img src={flutterLogo} alt="Flutter" />
                            <p>Flutter</p>
                        </div>
                        <div class="tech-item">
                            <img src={nodeJsLogo} alt="Node.js" />
                            <p>Node.js</p>
                        </div>
                        <div class="tech-item">
                            <img src={pythonLogo} alt="Python" />
                            <p>Python</p>
                        </div>
                        <div class="tech-item">
                            <img src={awsLogo} alt="Python" />
                            <p>AWS</p>
                        </div>
                        <div class="tech-item">
                            <img src={dotnetLogo} alt="Node.js" />
                            <p>.Net Microservices</p>
                        </div>
                        <div class="tech-item">
                            <img src={msSqlLogo} alt="Python" />
                            <p>MS Sql</p>
                        </div>
                        <div class="tech-item">
                            <img src={mySqlLogo} alt="Python" />
                            <p>My Sql</p>
                        </div>
                        <div class="tech-item">
                            <img src={mongoDbLogo} alt="Python" />
                            <p>Mongo Db</p>
                        </div>
                    </div>
                    <button class="scroll-right">&gt;</button>
                </div>
            </section>

            {/* Testimonials/Clients */}
            <section className="testimonials">
                <h2>Trusted by Leading Businesses</h2>
                <div className="testimonial-card">
                    <p>"Ai Network has transformed the way we manage our clinics."</p>
                    <p>- Client Name, Company</p>
                </div>
            </section>

            {/* Call to Action */}
            <section className="cta-banner">
                <h2>Ready to Transform Your Ideas into Software?</h2>
                <button className="primary-btn">Contact Us</button>
                <button className="secondary-btn">Schedule a Demo</button>
            </section>

            {/* Footer */}
            <footer className="footer">
                <div className="footer-info">
                    <h3>Ai Network</h3>
                    <p>Innovative software solutions for your business.</p>
                </div>
                <div className="footer-links">
                    <a href="#about">About Us</a>
                    <a href="#products">Products</a>
                    <a href="#technologies">Technologies</a>
                    <a href="#blog">Blog</a>
                    <a href="#contact">Contact</a>
                </div>
                <div className="social-media">
                    <a href="#linkedin">LinkedIn</a>
                    <a href="#twitter">Twitter</a>
                </div>
            </footer>
        </div>
    );
};

export default HomePage;
